

html {
  font-size: 62.5%
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  background-color: #022c43;
  overflow:hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


